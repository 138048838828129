import { HttpClient } from '@angular/common/http';
import { DatabaseDetailsService } from '@sympheny/database/model';
import {
  BuildingType,
  BuildingTypeSchema,
  EnergyDemand,
  EnergyDemandType,
  EnergyDemandTypeSchema,
} from '@sympheny/project/scenario/data-access';
import {
  LoadDataService,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import {
  Observable,
  ReplaySubject,
  firstValueFrom,
  map,
  of,
  switchMap,
} from 'rxjs';

import {
  EnergyDemandAgeDatabase,
  EnergyDemandAgeDatabaseSchema,
  EnergyDemandDatabase,
  EnergyDemandDatabaseSchema,
} from '../model/energy-demand-database.model';

export abstract class AbstractEnergyDemandDatabaseCollection
  implements DatabaseDetailsService<any>, LoadDataService
{
  private readonly base = this.environmentService.getValue('base');
  protected readonly idKey = 'energyDemandType';

  public readonly categories$ = new ReplaySubject<any>(1);

  constructor(
    protected readonly fromOrg: boolean,
    protected readonly http: HttpClient,
    protected readonly environmentService: EnvironmentService,
  ) {}

  public load(): void {
    firstValueFrom(this.fetchApi()).then((categories) =>
      this.categories$.next(categories),
    );
  }

  public reload(): void {
    this.load();
  }

  public create(partialData: Partial<any>, ...extraParams: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  protected fetchApi(): Observable<EnergyDemandType[]> {
    return this.http
      .get<
        ResponseModel<{ types: EnergyDemandType[] }>
      >(`${this.base}database-energy-demands/types`, { params: { fromOrg: this.fromOrg } })
      .pipe(mapDataZ(EnergyDemandTypeSchema, 'types'));
  }

  public getTechnologyCategoryDetails(types: string[]) {
    return this.getBuildingUseByType(types[0]);
  }

  public getBuildingUseByType(energyDemandType: string | null) {
    if (!energyDemandType) {
      return of(undefined);
    }

    return this.http
      .get<
        ResponseModel<{ buildingTypes: BuildingType[] }>
      >(`${this.base}database-energy-demands/types/${energyDemandType}`, { params: { fromOrg: this.fromOrg } })
      .pipe(mapDataZ(BuildingTypeSchema, 'buildingTypes'));
  }

  public getDetails(
    energyDemandType: string,
    buildingType: string,
    exchangeRate: number,
  ): Observable<any> {
    if (!energyDemandType || !buildingType) {
      return of(undefined);
    }

    return this.getEnergyDemandsAgeByBuildingUse(
      energyDemandType,
      buildingType,
    );
  }
  /**
   * Get demand age by type for energy demands
   */
  private getEnergyDemandsAgeByBuildingUse(
    preSelectedDemandType: string,
    preSelectedBuildingUse: string,
  ): Observable<EnergyDemandDatabase> {
    // find buildingtypeId
    return this.getBuildingUseByType(preSelectedDemandType).pipe(
      map(
        (types) =>
          types.find(
            (t) =>
              t.buildingType === preSelectedBuildingUse ||
              t.buildingTypeDisplayValue === preSelectedBuildingUse,
          )?.buildingType ?? preSelectedBuildingUse,
      ),
      switchMap((buildingUse) =>
        this.http.get<ResponseModel<BuildingType>>(
          `${this.base}database-energy-demands/types/${preSelectedDemandType}/building-types/${buildingUse}`,
          { params: { fromOrg: this.fromOrg } },
        ),
      ),
      mapDataZ(EnergyDemandDatabaseSchema),
    );
  }

  public getEnergyDemandProfile(
    preselectedEnergyDemandGuid: string,
  ): Observable<EnergyDemandAgeDatabase> {
    return this.http
      .get<
        ResponseModel<EnergyDemandAgeDatabase>
      >(`${this.base}database-energy-demands/${preselectedEnergyDemandGuid}`, { params: { fromOrg: this.fromOrg } })
      .pipe(mapDataZ(EnergyDemandAgeDatabaseSchema));
  }

  public update(gui: string, data: Partial<EnergyDemand>): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public deleteCategory(guid: string): Promise<string> {
    throw new Error('not implemented yet');
  }

  public delete(gui: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
