import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export abstract class DatabaseDetailsService<TECH> {
  public readonly noCategories?: boolean;
  public readonly hasTypes?: boolean;

  public abstract readonly categories$: Observable<any[]>;

  public abstract reload(): void;
  public abstract getTechnologyCategoryDetails(
    categories: string[],
  ): Observable<any[]>;
  public getCategoryTypeDetails?(
    category: string,
    type: string,
  ): Observable<any[]>;

  public abstract getDetails(
    // projectVersion: ProjectVersion,
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any>;
  public abstract create(
    partialData: Partial<TECH>,
    ...extraParams: any
  ): Promise<TECH | TECH[] | any | void>;
  public abstract update(
    gui: string,
    data: Partial<TECH>,
  ): Promise<TECH | TECH[] | any | void>;
  public abstract delete(gui: string): Promise<string>;
  public abstract deleteCategory(gui: string): Promise<string>;
}

@Injectable({ providedIn: 'root' })
export class DefaultDatabaseDetailsService
  implements DatabaseDetailsService<any>
{
  public noCategories?: boolean = true;
  public categories$: Observable<any[]> = of(null);
  public types$$: Observable<any[]> = of(null);
  public reload(): void {
    console.warn('DefaultDatabaseDetailsService - Method not implemented.');
  }
  public getTechnologyCategoryDetails(categories: string[]): Observable<any[]> {
    console.warn('DefaultDatabaseDetailsService - Method not implemented.');
    return of(null);
  }
  public getDetails(
    categories: string,
    guid: string,
    exchangeRate: number,
  ): Observable<any> {
    console.warn('DefaultDatabaseDetailsService - Method not implemented.');
    throw new Error('Method not implemented.');
  }
  public create(partialData: Partial<any>, ...extraParams: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
  public update(gui: string, data: Partial<any>): Promise<any> {
    throw new Error('Method not implemented.');
  }
  public delete(gui: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
  public deleteCategory(gui: string): Promise<string> {
    throw new Error('Method not implemented.');
  }
}
