<div class="p-6" *transloco="let t">
  <h2>
    {{ t(data.title) }}
  </h2>
  <div
    class="pb-4 max-h-[75VH] overflow-auto"
    [innerHTML]="t(data.question, data.translateParams)"
  ></div>

  <sympheny-dialog-actions
    [submitLabel]="data.submitLabel ?? data.isCopy ? 'Continue' : 'Delete'"
    [submitColor]="data.submitLabel ?? data.isCopy ? 'primary' : 'warn'"
    (submitAction)="submitAction()"
    [disabled]="loading"
  >
  </sympheny-dialog-actions>
</div>
<div class="absolute w-full h-full flex">
  <sympheny-loader
    class="items-center w-full"
    [loading]="loading"
  ></sympheny-loader>
</div>
